.accordion {
	font-family: 'Quicksand';
	-webkit-box-shadow: 0px 13px 23px -13px rgba(0,0,0,0.5);
	width: auto;
	display: flex;
	flex-direction: column;
	background-color: transparent;
	margin: auto;
	margin-top: 50px;
}

.title-wrapper {
	margin: 0 10px;
}

.title {
 height: 30px;
 width: 100%;
 background-color: transparent;
 color: #cedaff;
 text-transform: uppercase;
 letter-spacing: 1px;
 text-align: left;
 line-height: 1.5;
 font-weight: lighter;
 position: relative;
 padding: 15px 5px;
 z-index: 2000;
 border-radius: 4px;
 margin-top: 5px;
 transition: all .2s ease-in;
}

.title-text {
 margin-right: 15px;
}

.title:hover {
 cursor: pointer;
 color: whitesmoke;
 background-color: rgba(68, 68, 68, 0.2);
}

.title:active {
 background-color: rgba(0, 0, 0, .55);
}

.content {
 height: 30px;
 width: 100%;
 background-color: transparent;
 border-radius: 4px;
 color: white;
 font-size: 16px;
 text-align: center;
 position: relative;
 z-index: 1000;
 margin-top: -30px;
 text-align: left;
 transition: all 200ms cubic-bezier(0.600, -0.280, 0.735, 0.045);
}

.content-open {
 margin-top: 0px;
 padding-bottom: 35px;
 height: fit-content;
 background-color: rgba(51, 51, 51, 0.5);
 transition: all 350ms cubic-bezier(0.080, 1.090, 0.320, 1.275);
}

.content-text {
 padding: 15px;
 visibility: hidden;
 opacity: 0;
 overflow: auto;
 transition: all .2s ease-in;
}

.content-text-open {
 visibility: visible;
 opacity: 1;
 transition: all .8s ease-in;
}

.arrow-wrapper {
 position: absolute;
 right: 10px;
}

@media (max-width: 450px) {
	.title {
		margin: 10px 0;
	}
}
