
/* ---Top Section--- */
#overlay {
	position: fixed;
  width: 100%; 
  height: 100%; 
  background-color: rgba(0, 0, 0, 0.301); 
  z-index: 2; 
}
.top-container {
	background-image: url('../../resources/home-header.jpg');
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 90vh;
	padding-bottom: 2rem;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	z-index: 1;
}

.top-container-box {
	display: flex;
	flex-direction: column;
	position: absolute;
	bottom: 0%;
	left: 5%;
	justify-self: center;
	line-height: 0;
	padding: .5rem 1.5rem;
	align-self: center;
	margin: .5rem;
	transition: .25s;
	font-size: 17px;
	font-family: 'Jura';
}

/* ---Services--- */
.services {
	background-color: #fffcf5;
	padding-bottom: 3%;
	width: 100%;
	top: 0;
	text-align: center;
}

.services ul {
	list-style: none;
	padding: 20px;
	text-align: left;
}

.services li {	
	font-size: 15px; 
	font-weight: 400; 
}

.services h1 {
	justify-self: center;
	font-size: 3rem;
	font-weight: 400;
	font-family: 'Raleway';
	color: black;
	text-decoration: 1px solid underline black;
	padding: 2rem 0;
	margin: 0;
}

.services-row {
	display: flex;
	justify-content: space-evenly;
	flex-direction: row;
	flex-wrap: wrap;
	height: 100%;
	margin: 2rem;
}

.card {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	align-self: center;
	text-align: center;
	border-radius: 10px;
	background-color: #cececea8;
	color: black;
	height: 400px;
	width: 256px;
	transition: .4s;
	margin: 10px;
	box-shadow: 0px 5px 8px 2px rgb(0 0 0 / 20%);
} .card:hover {
		box-shadow: -2px 2px 12px 3px rgb(0 0 0 / 50%);
	}
.card-btn {
	cursor: pointer;
	transition: .4s;
	width: 100%;
	font-family: 'Lato';
	font-weight: 700;
	background-color: #00a1ff4a;
	vertical-align: baseline;
} .card-btn:hover {
	background-color: #00adff91;
}

.card-header {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 5%;
}

.card-header img {
	height: 64px;
	width: 64px;
	margin: 10px;
}

/* ---Client Section---*/
.client-section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin: 40px 0;
}
.client-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	max-width: 85%;
	min-width: 50%;
}
.client-box {
	width: 300px; 
	height: 155px;
	padding: 10px;
	transition: .3s;
}
.client-box:hover {
	transform: scale(1.1);
}

/* ---Banner--- */
.banner {
	display: flex;
	justify-content: center;
	color: whitesmoke;
	background-color: rgb(0, 102, 255);
	font-family: 'Lato';
	padding: 2rem;
	text-align: center;
	text-transform: uppercase;
}
.banner h1 {
	font-size: 2rem;
}

.banner h1 span {
	font-weight: 400;
	font-size: 1.3rem;
}

/* ---Standout--- */

.standout {
	font-family: Roboto;
	padding: 1rem 0;
	width: 100%;
	background-color: #dfdfdf;
	color: black;
}

.standout h1 {
	text-align: center; 
	margin: 3rem;
	font-size: 26px;
	font-style: italic;
}

.box {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	font-family: 'Lato';
	text-align: left;
	width: 300px;
	padding: 20px;
	margin: 10px;
	border: 1px solid #476caf;
}
	.box:hover {
		border: 2px solid #1c4aa0;;
	}

/* ---Comparison--- */
.comparison {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-family: 'Lato';
	background-color: white;
	padding: 3rem 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
	min-width: 95vw;
}

table span {
	font-size: 10px;
	color: rgb(139, 139, 139);
	vertical-align: text-top;
}

.table-header {
	background-color: #40bbe0;
	border-bottom: 3px solid black;
}

.table-header img {
	width: 160px;
	height: 40px;
	font-weight: 300;
}

th, td {
  text-align: center;
  padding: 16px;
	color: black;
	border: 1px solid black;
}

.table-sm-txt {
	color: rgb(75, 75, 75);
	font-size: 12px;
}

th:first-child, td:first-child {
  text-align: left;
}

tr:nth-child(even) {
  background-color: wheat
}

tr:nth-child(odd) {
	background-color: #ebebeb8a;
}

/* ---CTA--- */
.cta {
	font-weight: 400;
  font-family: 'Raleway';
}

.cta h1, h3 {
	font-weight: 300; 
	font-family: Roboto;
}

.cta h1 {
	text-align: center;
	margin-top: 4rem;
	font-size: 28px;
}

.cta h3 {
	text-align: center;
	margin: 2rem;
	font-size: 22px;
	color: #cccccccc;
}

.cta-box {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	text-align: left;
	width: 300px;
	padding: 20px;
	margin: 10px;
}

.cta-box p {
	font-family: 'Roboto';
	font-weight: 300;
}

@media (min-width: 2000px) {
	.client-box {width: 450px; height: 230px;}
}

@media (max-width: 768px) {
	.top-container-box {line-height: normal;}
	.client-box {width: 200px; height: 110px;}
}

@media (max-width: 520px) {
	th, td {
		padding: 10px;
	}
	.table-header img {
		width: 80px;
		height: 20px;
	}
}
