.header {
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;    
	align-items: flex-start;
	justify-content: space-around;
	width: 100%;
	padding: 20px;
}

.header-txt {
	width: 40%;
}

.header-txt h1 {
	font-size: 59px;
	font-weight: 300;
	font-family: 'Lato';
}

.header-txt h4 {
	font-size: 22px;
	font-weight: 300;
	font-family: 'Lato';
	color: #cacaca
}

.team-section {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 30px;	
	height: fit-content; 
}

.team-section img {
	width: 400px;
	height: auto;
	border-radius: 25px;
}

.team-section h3 {
	color: #cacaca;
}

.team-text {
	display: flex;
	width: 50%;
	align-items: center;
	flex-direction: column;
	font-weight: 300;
	font-family: 'Lato';
}

.team-text p {font-size: 18px;}


@media (max-width: 768px) {
	main {
		flex-direction: column; 
		justify-content: center;
		align-items: center;
		margin: 5%;
	}
	.header {	flex-direction: column;}
	.header-txt {width: auto;}
	.header-txt h1 {font-size: 50px;}
	.header-txt h4 {font-size: 20px;}
	iframe {width: 95%; height: 300px;}
	.team-section {padding: 10px; width: 50%;}
	.team-section img {width: 200px;}
	.team-text {width: 100%;}
	.team-text p {font-size: 16px;}
}
