@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,400;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,400&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Quicksand:300,400,700');
@import url('https://fonts.googleapis.com/css2?family=Jura:wght@300;400;700&family=Maven+Pro&display=swap');

:root {
	--shadow: 0 12px 5px rgba(104, 104, 104, 0.8);
	--drk-blue: #44548c;
	--light-blue: #44758c;
	--dark-red: #b81e1e;
	--light-red: #b8341e;
	--light:#dcdde2;
	--dark:#5e6668;
}

html {
	box-sizing: border-box;
	font-family: 'Roboto', sans-serif;
	background-color: rgb(0, 0, 0);
	color: whitesmoke;
}

body {
	margin: 0;
}
button {
	background-color: transparent;
}

main {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	padding: 7% 0;
	margin: 5%;
	background-color: transparent;
	border-top: 1px white solid;
}

.wrapper {
	width: 100%;
	height: 100%;
}

.row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.column {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.icon {
	height: 64px;
	width: 64px;
}

.sm-btn {
	font-size: 16px;
  padding: 10px;
  width: 150px;
	border-radius: 8px;
	border: none;
}

.md-btn {
	font-size: 20px;
  padding: 15px;
  width: 200px;
	border-radius: 12px;
	border: none;
}

.lg-btn {
	font-size: 24px;
  padding: 20px;
  width: 300px;
	border-radius: 12px;
	border: none;
}

.arr-btn {
	display: inline-block;
  text-align: center;
  transition: all 0.5s;
  cursor: pointer;
	background-color: rgba(255, 255, 255, 0.24);
	border: 2px solid var(--light-blue);
  margin: 5px;
} .arr-btn span {
	cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
} .arr-btn span:after {
	content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -25px;
  transition: 0.5s;
} .arr-btn:hover {
	padding-right: 35px;
	border: 2px solid white;
} .arr-btn:hover span:after {
  opacity: 1;
  right: -15px;
}

/* PRODUCTS ITEM SELECTION BAR */

.top-section-box {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 2% 15%;
	padding: 10px;
	border-radius: 20px;
	line-height: 1.5;
}

.top-section-box h4 {
	font-weight: 300;
	font-size: 20px;
}

.componentSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 40px;
	margin: 0;
}

.itemsList {
	list-style-type: none;
	flex-direction: row;
	display: flex;
	justify-content: center;
	width: 100%;
	align-items: center;
	overflow-x: scroll;
}

.itemsList > ul {
  list-style-type: none;
	flex-direction: row;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	border: 1px solid white;
  border-radius: 8px;
	padding: 0;
	overflow-x: scroll;
}

.itemsList > ul > li {
  position: relative;
	border: .5px solid white;
	transition: .5s;
	height: 180px;
	width: 200px;
	cursor: pointer;
}

.itemsList > ul > li:hover {
	box-shadow: 0px 0px 11px 4px rgba(255, 255, 255, 0.623);
}

.activeLi {
	box-shadow: 0px 0px 11px 8px rgba(255, 255, 255, 0.623);
}

.item {
  display: flex;
	position: relative;
	cursor: pointer;
	padding: 10px 20px;
	flex-direction: column;
	align-content: center;
	align-items: center;
}

.itemSection {
	display: flex;
	flex-direction: row;
	width: 90%;
	justify-content: center;
	align-items: center;
	margin: 30px 0;
}

.imageSection {
  flex: auto auto 0 0;
  width: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.itemContent {
	display: flex;
	flex-direction: column;
	text-align: left;
	width: 60%;
	padding: 1rem 3rem;
	color: rgb(36, 36, 36);
	border-radius: 20px;
	margin-right: 20px;
	width: 50%;
}

.product-list {
	padding: 10px;
	margin: 0;
}

.bullet {
	text-align: left;
	font-size: 16px;
	padding: 5px 0;
	list-style: none;
	line-height: 1.5;
}

.image {
	height: 400px;
	width: 400px;
	border-radius: 8px;
}

.itemHeader {
	font-size: 20px;
	margin: 8px;
	font-family: 'Jura';
	color: white;
	transition: .5s ease-in-out;
}

.itemHeader.active {
	color: #3498db;
}

.listIcon {
	width: 64px;
	height: 64px;
}

.itemDescription { 
  display: none;
}


.activeItem {
  position: relative;
  overflow: hidden;
}

.activeItemDescription {
  display: block;
	font-size: 20px;
	line-height: 1.5;
}

.activeItem .arrowDown {
  display: none;
}

