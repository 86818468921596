#fs-frm input,
#fs-frm select,
#fs-frm textarea,
#fs-frm fieldset,
#fs-frm optgroup,
#fs-frm label,
#fs-frm #card-element:disabled {
font-family: inherit;
font-size: 100%;
color: #a5a5a5;
border: none;
border-radius: 0;
display: block;
width: 98%;
padding: 0;
margin: 0;
-webkit-appearance: none;
-moz-appearance: none;
}
#fs-frm label {color: black;}
#fs-frm legend,
#fs-frm ::placeholder {
font-size: .825rem;
margin-bottom: .5rem;
padding-top: .2rem;
display: flex;
align-items: baseline;
color: #a5a5a5;
}

/* border, padding, margin, width */
#fs-frm input,
#fs-frm select,
#fs-frm textarea,
#fs-frm #card-element {
border: 1px solid rgba(0,0,0,0.2);
background-color: rgba(255,255,255,0.9);
padding: .75em 1rem;
margin-bottom: 1.5rem;
}
#fs-frm input:focus,
#fs-frm select:focus,
#fs-frm textarea:focus {
background-color: white;
outline-style: solid;
outline-width: thin;
outline-color: gray;
outline-offset: -1px;
}
#fs-frm [type="text"],
#fs-frm [type="email"] {
width: 95%;
}
#fs-frm [type="button"],
#fs-frm [type="submit"],
#fs-frm [type="reset"] {
width: auto;
cursor: pointer;
-webkit-appearance: button;
-moz-appearance: button;
appearance: button;
color: black;
}
#fs-frm [type="button"]:focus,
#fs-frm [type="submit"]:focus,
#fs-frm [type="reset"]:focus {
outline: none;
}
#fs-frm [type="submit"],
#fs-frm [type="reset"] {
margin-bottom: 0;
}
#fs-frm select {
text-transform: none;
}

#fs-frm [type="checkbox"] {
-webkit-appearance: checkbox;
-moz-appearance: checkbox;
appearance: checkbox;
display: inline-block;
width: auto;
margin: 0 .5em 0 0 !important;
}

#fs-frm [type="radio"] {
-webkit-appearance: radio;
-moz-appearance: radio;
appearance: radio;
}

/* address, locale */
#fs-frm fieldset.locale input[name="city"],
#fs-frm fieldset.locale select[name="state"],
#fs-frm fieldset.locale input[name="postal-code"] {
display: inline;
}
#fs-frm fieldset.locale input[name="city"] {
width: 52%;
}
#fs-frm fieldset.locale select[name="state"],
#fs-frm fieldset.locale input[name="postal-code"] {
width: 20%;
}
#fs-frm fieldset.locale input[name="city"],
#fs-frm fieldset.locale select[name="state"] {
margin-right: 3%;
}
 
/*----Customization----*/

#contact-form {
    display: flex;
    justify-content: center;
    align-items: center;
}
 
form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    border: 2px black solid;
    border-radius: 15px;
    padding: 2rem;
    width: 80%;
    margin: 2rem;
    background-color: #d5e4e7;
}
.form-head {
    font-family: 'Lato';
    color: black;
}
#fs-frm [type="submit"] {
    background-color: #2c7c93;
    transition: .2s;
    border-radius: 20px;
} 
#fs-frm [type="submit"]:hover {
    background-color: #2c7d9393;
}

.response {
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.response img {
    width: 250px;
}

.response p {
    font-size: 20px;
    font-weight: 300;
    font-family: 'Lato';
}