.modal-container {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background: rgba(41, 41, 41, 0.85);
}
	
.modal {
	width: 70vw;
	height: 50vh;
	padding: 4rem 2rem;
	border-radius: .8rem;
	color: white;
	background: var(--light-blue);
	box-shadow: .4rem .4rem 2.4rem .2rem rgba(167, 167, 167, 0.151);
	position: relative;
	overflow: hidden;
}

.modal-details {
	text-align: center;
	margin-bottom: 1rem;
	padding-bottom: 1rem;
	border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.modal-title {
	font-size: 5vmin;
}

.modal-description {
	margin-top: 2rem;
	font-size: 3.3vmin;
	font-style: italic;
}

.modal-text {
	padding: 0 .5rem;
	margin-bottom: 2rem;
	font-size: 3vmin;
	line-height: 2;
}

.modal-text::before {
	content: '';
	position: absolute;
	top: 0%;
	left: 100%;
	transform: translate(-50%, -50%);
	width: 18rem;
	height: 18rem;
	border: 1px solid rgba(255, 255, 255, 0.2);
	border-radius: 100rem;
	pointer-events: none;
}
.modal-btn {
	padding: .8rem;
	border: 2px solid rgba(255, 255, 255, 0.4);
	border-radius: 100rem;
	color: inherit;
	position: relative;
	top: 10%;
  left: 37%;
	text-decoration: none;
	font-size: 3vmin;
	font-family: inherit;
	letter-spacing: .2rem;
	transition: .2s;
	cursor: pointer;
}
	.modal-btn:hover {
		border: black 2px solid;
	}

.modal-close {
	width: 2rem;
	height: 2rem;
	padding: 3%;
	border: 1px solid rgba(255, 255, 255, 0.4);
	border-radius: 100rem;
	color: inherit;
	background-color: transparent;
	position: absolute;
	top: 3%;
	right: 3%;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: .2s;
	cursor: pointer;
}
	.modal-close:hover {
		border-color: rgba(255, 255, 255, 0.8);
		transform: translateY(-.2rem);
		background-color: rgba(255, 99, 71, 0.842);
	}
	.modal-close::before {
	content: 'x';
	transform: translateY(-.1rem);
	font-size: 4vmin;
	}

/* Add Animation */
@-webkit-keyframes slideIn {
from {bottom: -300px; opacity: 0} 
to {bottom: 0; opacity: 1}
}

@keyframes slideIn {
from {bottom: -300px; opacity: 0}
to {bottom: 0; opacity: 1}
}

@-webkit-keyframes fadeIn {
from {opacity: 0} 
to {opacity: 1}
}

@keyframes fadeIn {
from {opacity: 0} 
to {opacity: 1}
}