		/*Middle Container*/
.process {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 2rem 0;
	font-family: 'Raleway';
}

.process img {
	color: white;
	vertical-align: bottom;
}

.process-box {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border: none;
	border-radius: 20px;
	color: black;
	background-color: #d5e4e7;
	width: 60vw;
	height: auto;
}

.process-box img {
	width: 48px;
}

.bx {
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.box p {
	font-size: 14px;
	text-align: center;
	color: rgba(0, 0, 0, 0.637);
}

.box-icon {
	display: flex;
	justify-content: start;
	align-items: flex-start;
	margin: 5px;
}

.divider {
	font-size: 8rem;
}

@media (max-width: 768px) {
	.process-box {flex-direction: column;}
}