/* NAVIGATION */

.nav-wrapper {
	width: 100%;
	position: sticky;
	top: 0;
	background-color: rgb(0, 0, 0);
	z-index: 9998;
}

.grad-bar {
	width: 100%;
	height: 10px;
	background: linear-gradient(-45deg, #ff0000, #961c1c, #124772, #0a0dcf);
	background-size: 400% 400%;
	-webkit-animation: gradbar 7s ease infinite;
	-moz-animation: gradbar 7s ease infinite;
	animation: gradbar 7s ease infinite;
}
  
.navbar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 61px;
	overflow: hidden;
} 

.navbar img {
	width: 10rem;
	height: auto;
	justify-self: start;
	align-content: center;
	margin-left: 40px;
}

.navbar ul {
	list-style: none;
	display: flex;
	width: 100%;
	justify-items: center;
	justify-content: space-around;
}

.nav-item a {
	color: rgb(255, 255, 255);
	width: auto;
	font-size: 0.9rem;
	font-weight: 400;
	font-family: Raleway, sans-serif;
	font-size: 1.2rem;
	text-decoration: none;
	transition: color 0.3s ease-out;
}

.nav-item a:hover {
	color: #3498db;
}

	
/* MOBILE MENU & ANIMATION */

.menu-toggle .bar{
	width: 25px;
	height: 3px;
	background-color: #3f3f3f;
	margin: 5px auto;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.menu-toggle {
	justify-self: end;
	margin-right: 25px;
	display: none;
}

.menu-toggle:hover{
	cursor: pointer;
}

#mobile-menu.is-active .bar:nth-child(2){
	opacity: 0;
}

#mobile-menu.is-active .bar:nth-child(1){
	-webkit-transform: translateY(8px) rotate(45deg);
	-ms-transform: translateY(8px) rotate(45deg);
	-o-transform: translateY(8px) rotate(45deg);
	transform: translateY(8px) rotate(45deg);
}

#mobile-menu.is-active .bar:nth-child(3){
	-webkit-transform: translateY(-8px) rotate(-45deg);
	-ms-transform: translateY(-8px) rotate(-45deg);
	-o-transform: translateY(-8px) rotate(-45deg);
	transform: translateY(-8px) rotate(-45deg);
}

/* KEYFRAME ANIMATIONS */
    
@-webkit-keyframes gradbar {
	0% {
			background-position: 0% 50%
	}
	50% {
			background-position: 100% 50%
	}
	100% {
			background-position: 0% 50%
	}
}

@-moz-keyframes gradbar {
		0% {
				background-position: 0% 50%
		}
		50% {
				background-position: 100% 50%
		}
		100% {
				background-position: 0% 50%
		}
}

@keyframes gradbar {
	0% {
			background-position: 0% 50%
	}
	50% {
			background-position: 100% 50%
	}
	100% {
			background-position: 0% 50%
	}
}

	/* ----- Media Queries ----- */

	@media screen and (max-width: 1120px) {
    
    .navbar ul {
    display: flex;
    flex-direction: column;
    position: fixed;
    justify-content: flex-start;
    top: 55px;
    background-color: #fff;
    width: 100%;
		padding: 0;
    height: calc(100vh - 55px);
    transform: translate(-100%);
		transition: all .7s ease;
    text-align: center;
    overflow: hidden;
  } 
  
  .navbar li {
    padding: 15px;
  }
  
  .navbar li:first-child {
    margin-top: 50px;
  }
  
  .navbar li a {
    font-size: 1.3rem;
  	color: black;
  }
   
  .menu-toggle, .bar {
    display: block;
    cursor: pointer;
  }
  
  .mobile-nav {
  transform: translate(0%)!important;
  margin: auto;
    }
}