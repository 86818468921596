li {
	font-size: 14px;
}
 
.column {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	list-style: none;
	padding: 0;
	margin: 0;
	width: 200px;
}

footer {
	background-color: #22222A;
	color:white;
	text-align: center;
	width: 100%;
	position: relative;
	bottom: 0;
}

.footer-nav {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: baseline;
}

.bottom-nav-link {
	padding: 1rem;
	text-decoration: none;
	text-align: center;
	font-size: 1.1rem;
	color: white;
	font-family: Raleway;
	transition: 0.3s ease-out;
} .bottom-nav-link:hover {
		color: #3498db;
	}

.footer-misc {
	font-size: 1rem;
	padding: .5rem;
	color: #2e9eff;
	margin: 0;
}
@media (max-width: 810px) {

}
@media (max-width: 500px) {
	.footer-nav {
		flex-direction: column;
		align-items: center;

	}
}
