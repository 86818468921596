
.top-section-security {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-family: 'Lato';
	background-image: url('../../resources/security-header.jpeg');
	height: 63vh;
	background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.top-section-security h1 {
	font-family: 'Jura';
	font-size: 32px;
	padding: 8px;
	box-shadow: 0px 10px 6px 5px #000000d1;
	border-radius: 10px;
	background-color: rgba(0, 0, 0, 0.522);
}


@media (max-width: 900px) {
	.itemSection p {font-size: 16px}
	.bullet {font-size: 14px;}
	.itemSection {flex-direction: column;	width: 100%;}
	.itemContent {width: 90%;}
}

@media (max-width: 630px) {
	.top-section-security h1, .componentSection h1 {font-size: 24px;}
	.top-section-security h4 {font-size: 14px;}
	.itemSection p {font-size: 14px;}
	.bullet {font-size: 12px;}
	.image {width: 300px; height: 200px;}
	.itemsList {width: 100vw;}
	.itemsList > ul > li {padding: 0;}
	.item {padding: 5px;}
	.itemContent ul {padding: 5px}
}

@media (max-width: 630px) {
	.itemHeader {font-size: 12px;}
}